.App {
    margin: 1em;
    display: flex;
    width: 100%;
    flex-direction: column;
}
.App-UserName {
    display: flex;
}
.App-Content {

}

.App-Environments {
    width: 50%;
}
