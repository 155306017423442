.Environment-Header-Bar {
    display: flex;
    justify-content: space-between;
}
.Environment-Header-Bar-Actions {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Environment-Action {
    padding-left: 0.5em;
}
