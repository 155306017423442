.Resource {
    display: flex;
    justify-content: space-between;
}
.Resource-Data {
    flex: 1;
}

.Resource-Data:first-child {
    text-align: left;
}
.Resource-Data:not(:last-child):not(:first-child) {
    text-align: center;
}
.Resource-Data:last-child {
    text-align: right;
}
